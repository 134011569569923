import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

const LatestPosts = ({ skip, limit }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          allFacebookPosts {
            edges {
              node {
                created_time(formatString: "DD/MM/YYYY")
                full_picture
                id
                message
              }
            }
          }
        }
      `}
      render={(data) => {
        const posts = data.allFacebookPosts.edges;
        return (
          <div className="md:container mx-6 flex flex-col md:flex-row justify-around items-start flex-wrap md:mx-auto">
            {posts.slice(skip, limit).map(({ node: post }) => (
              <div
                data-aos="fade-up"
                key={post.id}
                className="inline-block w-full md:w-3/12 md:mx-6 my-6 bg-white border border-gray-300 shadow-lg rounded py-6 px-4"
              >
                <img
                  src={post.full_picture}
                  className="w-full pb-4 mx-0"
                  alt="dulce"
                />
                <p className="text-sm text-gray-600">{post.created_time}</p>
                <p className="break-words text-gray-700">{post.message}</p>
              </div>
            ))}
          </div>
        );
      }}
    ></StaticQuery>
  );
};
LatestPosts.propTypes = {
  limit: PropTypes.number,
  skip: PropTypes.number,
};

export default LatestPosts;
