import React from "react";
import PropTypes from "prop-types";

function Title(props) {
  const className = `title  -border ${props.twSize} font-bold text-center mb-12`;

  return <h2 className={className}>{props.titleName}</h2>;
}

Title.propTypes = {
  titleName: PropTypes.string,
  twSize: PropTypes.string,
};

export default Title;
