import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

function LinkCustom({ to, isOutline, children, extraStyle }) {
    let style = "bg-myPinkMedium hover:bg-myViolet text-white font-semibold p-4 rounded border border-myPinkMedium hover:border-myViolet ";

    if(isOutline) {
        style = "bg-transparent hover:bg-myViolet text-myPurple font-semibold py-4 px-4 rounded border border-myPurple hover:border-myViolet "
    }

    style += extraStyle;

    return(
        <Link to={to} className={style} >
            {children}
        </Link>
    )
}

LinkCustom.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string,
    isOutline: PropTypes.bool,
    extraStyle: PropTypes.string
}

export default LinkCustom;