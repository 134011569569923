import React, {useEffect} from "react";

import Aos from "aos";
import "aos/dist/aos.css";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Address from "../components/contactInfo/address";
import GMap from "../components/contactInfo/gMap";
import LatestPosts from "../components/latestPosts";
import Title from "../components/title";
import LinkCustom from "../components/linkAndButtons/linkCustom";

import feature1 from "../images/illustrations/features/undraw_outdoor.svg";
import feature2 from "../images/illustrations/features/undraw_booking.svg";
import feature3 from "../images/illustrations/features/undraw_cookie.svg";
import {
    FaPhone,
    FaBirthdayCake,
} from "react-icons/fa";

function IndexPage() {
    useEffect(() => {
        Aos.init({
            duration: 800,
            delay: 100,
            easing: "ease-out-cubic",
            once: true,
        });
    }, []);

    return (
        <Layout isBigLogo={true}>
            <SEO
                pageKeywords={[
                    `inicio`,
                ]}
                title="Inicio"
                lang="es"
            />
            <div className="intro flex flex-col">
                <section
                    className="flex flex-col xl:flex-row xl:justify-between h-full mx-6 md:mx-16 lg:mx-32 xl:mx-32">
                    <div className="description mb-16 mt-12 xl:mt-24">
                        <h1 className="text-3xl md:text-6xl font-bold mb-1">
                            Pasteleria Miss Dulces
                        </h1>
                        <p className="md:text-2xl mb-8 w-full md:w-2/3">
                            Somos una pasteleria y panadería con sede en Águilas. Desplácese
                            hacia abajo para saber más.
                        </p>
                        <div className="call-action flex flex-col items-start">
                            <LinkCustom
                                to="/dulces">
                                <FaBirthdayCake className="inline-block mb-1"/>
                                <span> Dulces</span>
                            </LinkCustom>
                            <LinkCustom
                                to="/contactar"
                                isOutline
                                extraStyle="ml-2 mt-2">
                                <FaPhone className="inline-block mb-1"/>
                                <span> Contactar</span>
                            </LinkCustom>
                        </div>
                    </div>
                    <div>
                        <GMap/>
                        <Address style="hidden xl:flex md:flex-row bg-white mt-10 border-2 rounded p-6"/>
                    </div>
                </section>
            </div>
            <section className="mx-12 md:flex items-start justify-around mb-16">
                <div
                    data-aos="fade-right"
                    className="md:mx-4 presentation-segment flex flex-col items-center justify-around"
                >
                    <img className="w-64 h-64 md:my-6" src={feature1} alt=""/>
                    <div className="desc max-w-xs md:max-w-md">
                        <h1 className="text-md text-center md:text-left lg:text-2xl font-bold">
                            Preparamos sus pasteles basados en su tema de la fiesta.
                        </h1>
                        <h2 className="lg:text-md text-center md:text-left">
                            Hacemos pasteles para fiestas al aire libre, en interiores, o
                            cualquier tipo de evento
                        </h2>
                    </div>
                </div>
                <div
                    data-aos="fade-up"
                    className="presentation-segment md:mx-4 flex flex-col items-center justify-around"
                >
                    <img className="w-64 h-64 my-6" src={feature2} alt=""/>
                    <div className="desc max-w-xs md:max-w-md">
                        <h1 className="text-md text-center md:text-left lg:text-2xl font-bold">
                            Escoge una fecha y espera a tu pastel.
                        </h1>
                        <h2 className="lg:text-md text-center md:text-left">
                            Puede pedir su pastel aquí mismo en nuestra página web sin ningún
                            tipo de preocupaciones. Sólo siéntate y relájate y espera tu
                            pastel. (¡Pronto!)
                        </h2>
                    </div>
                </div>
                <div
                    data-aos="fade-left"
                    className="presentation-segment md:mx-4 flex flex-col items-center justify-around"
                >
                    <img className="w-64 h-64 my-6" src={feature3} alt=""/>
                    <div className="desc max-w-xs md:max-w-md">
                        <h1 className="text-md text-center md:text-left lg:text-2xl font-bold">
                            Disfruta de un aperitivo
                        </h1>
                        <h2 className="lg:text-md text-center md:text-left">
                            También hacemos aperitivos, galletas y bebidas basados en su
                            fiesta temática.
                        </h2>
                    </div>
                </div>
            </section>
            <section className="mb-16 md:mb-64 flex flex-col items-center">
                <Title titleName="Novedades" twSize="text-4xl"/>
                <LatestPosts limit={9}/>
                <LinkCustom to="/dulces">
                    ¡Ver más!
                </LinkCustom>
            </section>
        </Layout>
    );
}

export default IndexPage;
