import React from "react";

function gMap() {
    return (
        <iframe
            className="w-full max-w-2xl h-48 xl:h-64 border-2 border-myPinkMedium rounded shadow"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAT3ueb2z05JpQDu9tKl7h9MjgtNMEQa18&q=Pasteleria+MISS+DULCES,Aguilas"
            allowFullScreen
        />
    )
}

export default gMap;
